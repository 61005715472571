//CSVデータダウンロードページ
import React, { Fragment, useEffect, useState } from 'react';
import { Typography, Button, Form, message, Input, Select } from 'antd';
import Axios from 'axios';
import config from '../../Config/key';
import { useSelector } from 'react-redux';
import qs from 'qs';
const ExcelJS = require('exceljs');
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;

const CSVDownloadPage = (props) => {
  const [groups, setGroups] = useState([]); //グループ名とグループコード
  const [selGroupInfo, setSelGroupInfo] = useState([]); //選択されたグループ情報
  const [selGroupCode, setSelGroupCode] = useState(''); //選択されたグループコード
  const [survey, setSurvey] = useState([]); //全てのサーベイ情報
  const [questionSet, setQuestionSet] = useState([]); //全てのサーベイ情報
  const [selQuestionSetId, setSelQuestionSetId] = useState(''); //全てのサーベイ情報
  const [selSurveyInfo, setSelSurveyInfo] = useState([]); //選択されたサーベイ情報
  const [groupSurveySeasonInfo, setGroupSurveySeasonInfo] = useState([]); //選択されたグループに存在するサーベイシーズン
  const [selSurveySeasonInfo, setSelSurveySeasonInfo] = useState([]); //選択されたサーベイシーズン情報
  const [selSurveySeasonId, setSelSurveySeasonId] = useState(''); //選択されたサーベイシーズンID
  const [submitSW, setSubmitSW] = useState(true);
  const [userInfo, setUserInfo] = useState({});

  const usr = useSelector((state) => state.user);

  //yyyymmddhhmmss
  const ymdhms = () => {
    var date = new Date();
    return (
      date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      ('0' + date.getHours()).slice(-2) +
      ('0' + date.getMinutes()).slice(-2) +
      ('0' + date.getSeconds()).slice(-2)
      // date.getMilliseconds()
    );
  };
  //yyyy/mm/dd
  const ymd = (tdate) => {
    let date = new Date(tdate);
    // console.log({ tdate });
    return (
      date.getFullYear() +
      '/' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2)
      // date.getMilliseconds()
    );
  };
  const readDB = async () => {
    try {
      if (!usr.userData) return null;
      setUserInfo(usr.userData);
      // console.log({ usr });
      // const groupInfo = await Axios.get('api/group/');

      const [surveyInfo, groupInfo] = await Promise.all([
        Axios.get('/api/survey/'), //DBからサーベイ情報取得
        Axios.get('/api/group/'), //DBからグループ情報取得
        // Axios.get('/api/surveySeason/'), //DBからシーズン情報取得
        // Axios.get(config.USER_SERVER), //全てのユーザの習得（管理者以外）
      ]);

      if (usr.userData.groupCode === 'RECRNT' && usr.userData.role === 2) {
        const nonGroup = groupInfo.data.groups.filter(
          (item) => item.group_code !== 'RECRNT'
        );
        setGroups(nonGroup);
      } else {
        setGroups(groupInfo.data.groups);
      }

      setSurvey(surveyInfo.data.survey);
      // setGroups(groupInfo.data.groups);

      if (!groups) throw 'グループ取得に失敗しました。';
      if (!survey) throw 'サーベイ取得に失敗しました。';
      if (usr.userData.role === 3) {
        //企業管理者
        // onChangeGroup(usr.userData.groupCode);
        let sgi = groupInfo.data.groups.find(
          (item) => item.group_code === usr.userData.groupCode
        );

        // console.log({ sgi });
        setSelGroupCode(usr.userData.groupCode);
        setSelGroupInfo(sgi);
        setGroupSurveySeasonInfo(sgi.survey_season);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ Users });
  }, [usr]);

  const onChangeGroup = (value) => {
    let sgi = groups.find((item) => item.group_code === value);
    // console.log({ sgi });
    setSelGroupCode(value);
    setSelGroupInfo(sgi);
    setGroupSurveySeasonInfo(sgi.survey_season);
    setSelSurveySeasonId('');
    setSelQuestionSetId('');
    setQuestionSet([]);
  };

  const onChangeSurveySeason = async (value) => {
    const sssi = groupSurveySeasonInfo.find((item) => item._id === value); //selSurveySeasonInfo
    setSelSurveySeasonId(value);
    setSelSurveySeasonInfo(sssi);
    // console.log({ sssi });
    const ssi = survey.find((item) => item._id === sssi.survey_id);
    setSelSurveyInfo(ssi);
    // console.log({ ssi });
    setQuestionSet(sssi.question_set);
    if (sssi.question_set.length > 0) {
      setSelQuestionSetId(sssi.question_set[0]._id);
    } else {
      setSelQuestionSetId('');
    }
    // console.log({ selQuestionSetId });
  };

  const onChangeQuestionSet = async (value) => {
    // console.log({ value });
    setSelQuestionSetId(value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      message.loading({
        content: 'データ作成中...',
        key: 'makecsv',
        duration: 20,
      });
      setSubmitSW(false);
      let res, res2;
      let answers;
      let surveyTokenArr;
      // let excelData = [];
      let tempData = {};
      const workbook = new ExcelJS.Workbook();
      workbook.addWorksheet('sheet1');
      const worksheet = workbook.getWorksheet('sheet1');
      let columns = [
        { header: 'ID', key: 'user_id' },
        { header: '会社名', key: 'company' },
        { header: '社員番号', key: 'empNo' },
        { header: '部署名①', key: 'department' },
        { header: '部署名②', key: 'department2' },
        { header: '部署名③', key: 'department3' },
        { header: '部署名④', key: 'department4' },
        { header: '部署名⑤', key: 'department5' },
        { header: 'eメール', key: 'email' },
        { header: '名前', key: 'name' },
        { header: 'ロール', key: 'role' },
        { header: '提出日時', key: 'study_end_date' },
      ];

      switch (selSurveyInfo.survey_code) {
        case '10010': //人事評価力診断　→
        case '50010': //モチベーショントレンド診断　→
        case '60010': //FS診断　→
        case '70010': //女性活躍診断
        case '80010': //ダイバーシティ組織診断
        case '90101': //人事評価力診断　コース
        case '90501': //モチベーション・トレンド診断　コース
          res = await Axios.get(
            `/api/surveyToken/g/${selGroupCode}/s/${selSurveySeasonId}`
          );
          // console.log(res.data);
          surveyTokenArr = res.data.tokenInfo;
          for (let index = 0; index < surveyTokenArr.length; index++) {
            const element = surveyTokenArr[index];
            tempData = {};
            // res2 = await Axios.get(`/api/dbModel100/${element._id}`);
            answers = element.answer_id.answers;
            // tokenInfoArr.push(res2.data.answers);
            tempData.user_id = element.user._id;
            tempData.company = element.user.company;
            tempData.empNo = element.user.emp_no;
            tempData.department = element.user.department;
            tempData.department2 = element.user.department2;
            tempData.department3 = element.user.department3;
            tempData.department4 = element.user.department4;
            tempData.department5 = element.user.department5;
            tempData.email = element.user.email;
            tempData.name = `${element.user.lastname} ${element.user.name}`;
            tempData.role = element.user.role;
            tempData.study_end_date = element.answer_id.study_end_date;
            for (let i = 0; i < answers.length; i++) {
              const element2 = answers[i];
              tempData[i + 1] = element2;
              if (index === 0) {
                columns.push({
                  header: `${i + 1}問`,
                  key: (i + 1).toString(),
                });
              }
            }
            if (index === 0) worksheet.columns = columns;
            worksheet.addRow(tempData);

            // excelData.push(tempData);
          }

          break;
        case '90301': //E3-Survey　コース
        case '30010': //E3-Survey
          columns.push(
            { header: '役職', key: 'position' },
            { header: '等級', key: 'level_code' },
            { header: '性別', key: 'sex' },
            { header: '生年月日', key: 'birthday' },
            { header: '入社日', key: 'join_company' },
            { header: '雇用携帯', key: 'emp_type' },
            { header: '勤務地', key: 'work_location' },
            { header: '勤務時間制度・職制', key: 'work_time_rule' },
            { header: '勤務時間', key: 'work_time' },
            { header: '入社形態', key: 'join_type' },
            { header: '出向状態', key: 'go_out' },
            { header: '職種', key: 'job_type' }
          );
          res = await Axios.get(
            `/api/surveyToken/g/${selGroupCode}/s/${selSurveySeasonId}`
          );
          const qInfo = await Axios.get(`/api/question/30010`);
          // console.log(qInfo.data.question);

          surveyTokenArr = res.data.tokenInfo;
          for (let index = 0; index < surveyTokenArr.length; index++) {
            const element = surveyTokenArr[index];

            const arr = element.user.change_history;

            if (arr.length > 1) {
              //履歴を過去→未来順にsort
              element.user.change_history = arr.sort(function (a, b) {
                return a.input_date < b.input_date ? -1 : 1; //オブジェクトの昇順ソート
              });
              let tempHistory = {};
              //履歴を繰り返す
              for (let i = 0; i < element.user.change_history.length; i++) {
                //履歴がサーベイ開始日より過去の場合、Pickup

                if (
                  element.user.change_history[i].input_date <
                  selSurveySeasonInfo.survey_start_date
                ) {
                  tempHistory = element.user.change_history[i];
                }
              }
              if (
                tempHistory['department'] !== undefined &&
                tempHistory['department']
              )
                element.user.department = tempHistory.department;
              if (
                tempHistory['department2'] !== undefined &&
                tempHistory['department2']
              )
                element.user.department2 = tempHistory.department2;
              if (
                tempHistory['department3'] !== undefined &&
                tempHistory['department3']
              )
                element.user.department3 = tempHistory.department3;
              if (
                tempHistory['department4'] !== undefined &&
                tempHistory['department4']
              )
                element.user.department4 = tempHistory.department4;
              if (
                tempHistory['department5'] !== undefined &&
                tempHistory['department5']
              )
                element.user.department5 = tempHistory.department5;
              if (
                tempHistory['position'] !== undefined &&
                tempHistory['position']
              )
                element.user.position = tempHistory.position;
              if (
                tempHistory['level_code'] !== undefined &&
                tempHistory['level_code']
              )
                element.user.level_code = tempHistory.level_code;
              if (
                tempHistory['emp_type'] !== undefined &&
                tempHistory['emp_type']
              )
                element.user.emp_type = tempHistory.emp_type;
              if (tempHistory['go_out'] !== undefined && tempHistory['go_out'])
                element.user.go_out = tempHistory.go_out;
              if (
                tempHistory['inactive'] !== undefined ||
                !tempHistory['inactive']
              ) {
                element.user.inactive = false;
              } else {
                element.user.inactive = true;
              }
            }

            if (!element.user.inactive) {
              tempData = {};
              // res2 = await Axios.get(`/api/dbModel100/${element._id}`);
              answers = element.answer_id.answers;
              // tokenInfoArr.push(res2.data.answers);
              tempData.user_id = element.user._id;
              tempData.company = element.user.company;
              tempData.empNo = element.user.emp_no;
              tempData.department = element.user.department;
              tempData.department2 = element.user.department2;
              tempData.department3 = element.user.department3;
              tempData.department4 = element.user.department4;
              tempData.department5 = element.user.department5;
              tempData.email = element.user.email;
              tempData.name = `${element.user.lastname} ${element.user.name}`;
              tempData.role = element.user.role;
              tempData.study_end_date = element.answer_id.study_end_date;
              tempData.position = element.user.position; //役職
              tempData.level_code = element.user.level_code; //等級
              switch (element.user.sex) {
                case 1:
                  tempData.sex = '男性';
                  break;
                case 2:
                  tempData.sex = '女性';
                  break;
                case 3:
                  tempData.sex = 'その他';
                  break;
                case 4:
                  tempData.sex = '回答しない';
                  break;
                default:
                  tempData.sex = '未登録';
                  break;
              }
              tempData.birthday = ymd(element.user.birthday); //生年月日
              tempData.join_company = ymd(element.user.join_company); //入社日
              tempData.emp_type = element.user.emp_type; //雇用携帯
              tempData.work_location = element.user.work_location; //勤務地
              tempData.work_time_rule = element.user.work_time_rule; //勤務時間制度・職制
              tempData.work_time = element.user.work_time; //勤務時間
              tempData.join_type = element.user.join_type; //入社携帯
              tempData.go_out = element.user.go_out; //出向状態
              tempData.job_type = element.user.job_type; //職種
              for (let i = 0; i < answers.length; i++) {
                const element2 = answers[i];
                tempData[i + 1] = element2;
                if (index === 0) {
                  columns.push({
                    header: `${i + 1}.${qInfo.data.question[i].question}`,
                    key: (i + 1).toString(),
                  });
                }
              }
              if (index === 0) worksheet.columns = columns;
              worksheet.addRow(tempData);
            }
            // excelData.push(tempData);
          }

          break;
        case '20110': //研修前後アンケート
        case '20210': //E3研修後アンケート
          res = await Axios.get(
            `/api/surveyToken/g/${selGroupCode}/s/${selSurveySeasonId}/q/${selQuestionSetId}`
          );
          // console.log({ questionSet });
          surveyTokenArr = res.data.tokenInfo;
          let selQuestionSetInfo = questionSet.find(
            (item) => item._id === selQuestionSetId
          );
          // console.log({ selQuestionSetInfo });
          const questionArr = selQuestionSetInfo.question_id;
          let questionInfo = [];
          let qText = [];
          // console.log({ questionArr });

          for (let i = 0; i < questionArr.length; i++) {
            const q = questionArr[i];
            const qt = await Axios.get(`/api/question/id/${q}`);
            // console.log({ qt });
            questionInfo.push(qt.data.question);
            qText.push(qt.data.question.question);
          }
          // console.log({ questionInfo });
          // console.log({ surveyTokenArr });

          for (let index = 0; index < surveyTokenArr.length; index++) {
            const element = surveyTokenArr[index];
            tempData = {};
            // res2 = await Axios.get(`/api/dbModel100/${element._id}`);
            // answers = res2.data.answerInfo.answers;
            answers = element.answer_id.answers;
            // tokenInfoArr.push(res2.data.answers);
            tempData.user_id = element.user._id;
            tempData.company = element.user.company;
            tempData.empNo = element.user.emp_no;
            tempData.department = element.user.department;
            tempData.email = element.user.email;
            tempData.name = `${element.user.lastname} ${element.user.name}`;
            tempData.role = element.user.role;
            tempData.study_end_date = element.answer_id.study_end_date;
            for (let i = 0; i < answers.length; i++) {
              let element2 = '';
              // console.log(i);
              // console.log(questionInfo[i]);
              if (
                questionInfo[i].type === '10' &&
                questionInfo[i].choices.length === 5 &&
                questionInfo[i].choices[0] == 'そう思う' &&
                questionInfo[i].choices[4] == 'そう思わない'
              ) {
                element2 = 6 - answers[i];
              } else if (questionInfo[i].type === '10') {
                element2 = Number(answers[i]);
              } else {
                element2 = answers[i];
              }
              tempData[i + 1] = element2;
              if (index === 0) {
                if (qText[i]) {
                  columns.push({
                    header: `${qText[i]}`,
                    key: (i + 1).toString(),
                  });
                } else {
                  columns.push({
                    header: `${i}問`,
                    key: (i + 1).toString(),
                  });
                }
              }
            }
            if (index === 0) worksheet.columns = columns;
            worksheet.addRow(tempData);
          }

          // console.log({ surveyTokenArr }); //全ての情報が揃った。
          break;

        // case '30010': //E3-Survey
        //   res = await Axios.get(
        //     `/api/surveyToken/g/${selGroupCode}/s/${selSurveySeasonId}`
        //   );
        //   surveyTokenArr = res.data.tokenInfo;
        //   // console.log({ surveyTokenArr });
        //   for (let index = 0; index < surveyTokenArr.length; index++) {
        //     const element = surveyTokenArr[index];
        //     tempData = {};
        //     // res2 = await Axios.get(`/api/dbModel100/${element._id}`);
        //     // answers = res2.data.answerInfo.answers;
        //     answers = element.answer_id.answers;
        //     // tokenInfoArr.push(res2.data.answers);
        //     tempData.user_id = element.user._id;
        //     tempData.company = element.user.company;
        //     tempData.empNo = element.user.emp_no;
        //     tempData.department = element.user.department;
        //     tempData.email = element.user.email;
        //     tempData.name = `${element.user.lastname} ${element.user.name}`;
        //     tempData.role = element.user.role;
        //     tempData.study_end_date = element.answer_id.study_end_date;
        //     for (let i = 0; i < answers.length; i++) {
        //       const element2 = answers[i];
        //       tempData[i + 1] = element2;
        //       if (index === 0) {
        //         columns.push({
        //           header: `${i + 1}問`,
        //           key: (i + 1).toString(),
        //         });
        //       }
        //     }
        //     if (index === 0) worksheet.columns = columns;
        //     worksheet.addRow(tempData);
        //   }
        //   break;

        case '40010': //360度サーベイ
          res = await Axios.get(
            `/api/surveyToken/g/${selGroupCode}/s/${selSurveySeasonId}`
          );
          surveyTokenArr = res.data.tokenInfo;
          // console.log({ surveyTokenArr });
          for (let index = 0; index < surveyTokenArr.length; index++) {
            const element = surveyTokenArr[index];
            tempData = {};
            // res2 = await Axios.get(`/api/dbModel100/${element._id}`);
            // answers = res2.data.answerInfo.answers;
            answers = element.answer_id.answers;
            // tokenInfoArr.push(res2.data.answers);
            tempData.user_id = element.user._id;
            tempData.company = element.user.company;
            tempData.empNo = element.user.emp_no;
            tempData.department = element.user.department;
            tempData.email = element.user.email;
            tempData.name = `${element.user.lastname} ${element.user.name}`;
            tempData.role = element.user.role;
            tempData.study_end_date = element.answer_id.study_end_date;
            tempData.heroName = element.hero_name;
            switch (element.relation) {
              case 1:
                tempData.relation = '本人';
                break;
              case 2:
                tempData.relation = '上司';
                break;
              case 3:
                tempData.relation = '部下・同僚';
                break;
              default:
                break;
            }
            columns.push(
              { header: '対象者', key: 'heroName' },
              { header: '360役割', key: 'relation' }
            );
            if (index === 0) {
              for (let i = 0; i < 41; i++) {
                columns.push({
                  header: `${i + 1}問`,
                  key: (i + 1).toString(),
                });
              }
            }
            for (let i = 0; i < answers.length; i++) {
              const element2 = answers[i];
              tempData[i + 1] = element2;
            }

            if (index === 0) worksheet.columns = columns;
            worksheet.addRow(tempData);
          }
          break;

        default:
          break;
      }
      const uint8Array = await workbook.xlsx.writeBuffer();
      const blob = new Blob([uint8Array], {
        type: 'application/octet-binary',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      if (selQuestionSetId) {
        let selQuestionSetInfo = questionSet.find(
          (item) => item._id === selQuestionSetId
        );
        a.download = `Recurrent_Sruvey_RawData_${selSurveySeasonInfo.season_name}_${selQuestionSetInfo.set_name}.xlsx`;
      } else {
        a.download = `Recurrent_Sruvey_RawData_${selSurveySeasonInfo.season_name}.xlsx`;
      }
      a.click();
      // ダウンロード後は不要なのでaタグを除去
      a.remove();
      // console.log({ excelData });
      // console.log(excelData.length);
      message.success({
        content: 'ダウンロード完了！',
        key: 'makecsv',
        duration: 2,
      });
      setSubmitSW(true);
    } catch (error) {
      console.log({ error });
      message.error({
        content: 'ダウンロード失敗！',
        key: 'makecsv',
        duration: 2,
      });
    }
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '800px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>回答データCSVダウンロード</Title>
        </div>
        <Form onSubmit={onSubmit}>
          <br />
          <Title>❶</Title>
          <Text strong>企業を選択してください。（必須）</Text>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Select
              showSearch
              style={{ marginLeft: 0, marginRight: 10, width: 300 }}
              placeholder="企業選択"
              optionFilterProp="items"
              onChange={onChangeGroup}
              value={selGroupCode}
              filterOption={(input, option) =>
                option.props.items.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              disabled={userInfo.role === 3}
            >
              {groups.map((element) => {
                // console.log({ element });
                return (
                  <Option value={element.group_code} key={element._id}>
                    {element.group_name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <br />
          <div>
            <Title>❷</Title>
            <Text strong>サーベイを選択してください。（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{ marginLeft: 0, marginRight: 5, width: 400 }}
                placeholder="企業選択"
                optionFilterProp="items"
                onChange={onChangeSurveySeason}
                value={selSurveySeasonId}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {groupSurveySeasonInfo.map((element) => {
                  // console.log({ element });
                  return (
                    <Option value={element._id} key={element._id}>
                      {element.season_description}
                    </Option>
                  );
                })}
              </Select>

              {questionSet.length > 0 && (
                <span>
                  設問セット
                  <Select
                    showSearch
                    style={{ marginLeft: 5, marginRight: 5, width: 300 }}
                    placeholder="設問セット選択"
                    optionFilterProp="items"
                    onChange={onChangeQuestionSet}
                    value={selQuestionSetId}
                    defaultValue={questionSet[0].set_name}
                    filterOption={(input, option) =>
                      option.props.items
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {questionSet.map((element) => {
                      return (
                        <Option value={element._id} key={element._id}>
                          {element.set_name}
                        </Option>
                      );
                    })}
                  </Select>
                </span>
              )}
            </div>
          </div>
          <br />
          <br />
          <Title>❸</Title>
          <Button
            type="primary"
            size="large"
            onClick={onSubmit}
            disabled={
              !(
                submitSW &&
                selSurveySeasonId &&
                (setSelSurveySeasonInfo.survey_code != '20110' ||
                  setSelSurveySeasonInfo.survey_code != '20210' ||
                  selQuestionSetId)
              )
            }
          >
            ダウンロード
          </Button>
        </Form>
      </div>
    </Fragment>
  );
};

export default CSVDownloadPage;

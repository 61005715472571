import React, { Fragment, useEffect, useState } from 'react';
import { Typography, Button, Form, message, Select } from 'antd';
import Axios from 'axios';
import { useSelector } from 'react-redux';
const { Option } = Select;
const { Title, Text } = Typography;

const AssignQSetPage = (props) => {
  const [groups, setGroups] = useState([]); //グループ名とグループコード
  const [selGroupInfo, setSelGroupInfo] = useState([]); //選択されたグループ情報
  const [selGroupCode, setSelGroupCode] = useState(''); //選択されたグループコード
  const [survey, setSurvey] = useState([]); //全てのサーベイ情報
  const [questionSet, setQuestionSet] = useState([]); //全てのサーベイ情報
  const [hasQuestionSetId, setHasQuestionSetId] = useState([]); //全てのサーベイ情報
  const [selQuestionSetId, setSelQuestionSetId] = useState(''); //全てのサーベイ情報
  const [selSurveyInfo, setSelSurveyInfo] = useState([]); //選択されたサーベイ情報
  const [groupSurveySeasonInfo, setGroupSurveySeasonInfo] = useState([]); //選択されたグループに存在するサーベイシーズン
  const [selSurveySeasonInfo, setSelSurveySeasonInfo] = useState([]); //選択されたサーベイシーズン情報
  const [selSurveySeasonId, setSelSurveySeasonId] = useState(''); //選択されたサーベイシーズンID

  const usr = useSelector((state) => state.user);

  //yyyymmddhhmmss
  const ymdhms = () => {
    var date = new Date();
    return (
      date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      ('0' + date.getHours()).slice(-2) +
      ('0' + date.getMinutes()).slice(-2) +
      ('0' + date.getSeconds()).slice(-2)
      // date.getMilliseconds()
    );
  };

  const readDB = async () => {
    try {
      if (!usr.userData) return null;
      // console.log({ usr });
      // const groupInfo = await Axios.get('api/group/');

      const [surveyInfo, groupInfo, qSetInfo] = await Promise.all([
        Axios.get('/api/survey/'), //DBからサーベイ情報取得
        Axios.get('/api/group/'), //DBからグループ情報取得
        Axios.get('/api/questionSet/'), //DBからグループ情報取得
        // Axios.get('/api/surveySeason/'), //DBからシーズン情報取得
        // Axios.get(config.USER_SERVER), //全てのユーザの習得（管理者以外）
      ]);

      setSurvey(surveyInfo.data.survey);
      setGroups(groupInfo.data.groups);
      setQuestionSet(qSetInfo.data.questionSet);

      if (!groups) throw 'グループ取得に失敗しました。';
      if (!survey) throw 'サーベイ取得に失敗しました。';
      if (!questionSet) throw 'サーベイ取得に失敗しました。';
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ Users });
  }, [usr]);

  const onChangeGroup = (value) => {
    let sgi = groups.find((item) => item.group_code === value); //selectGroupInfo
    // console.log({ sgi });
    setSelGroupCode(value);
    setSelGroupInfo(sgi);
    setGroupSurveySeasonInfo(sgi.survey_season); //グループに紐づけられているシーズン情報
    setSelSurveySeasonId('');
    setSelQuestionSetId('');
  };

  const onChangeSurveySeason = async (value) => {
    const sssi = groupSurveySeasonInfo.find((item) => item._id === value); //selSurveySeasonInfo
    setSelSurveySeasonId(value);
    setSelSurveySeasonInfo(sssi);
    // console.log({ sssi });
    const ssi = survey.find((item) => item._id === sssi.survey_id);
    setSelSurveyInfo(ssi);
    let hasQSetId = [];
    sssi.question_set.map((element) => {
      hasQSetId.push(element._id);
    });
    setHasQuestionSetId(hasQSetId); //シーズンが既に持っている設問セットID
    setSelQuestionSetId('');

    // console.log(hasQSetId);
  };

  const onChangeQuestionSet = async (value) => {
    setSelQuestionSetId(value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      const selQuestionSetInfo = questionSet.find(
        (item) => item._id === selQuestionSetId
      );
      // console.log({ selGroupInfo });
      // console.log({ selSurveySeasonInfo });
      // console.log({ selQuestionSetInfo });
      const value = {
        groupId: selGroupInfo._id,
        seasonId: selSurveySeasonInfo._id,
        questionSetInfo: selQuestionSetInfo,
      };
      const res = await Axios.patch('/api/questionSet/assignSet', value);
      if (res.data.success) {
        // console.log(res.data.season);
        // console.log(res.data.group);
        message.success({
          content: '設問セット紐付け完了！',
          key: 'makecsv',
          duration: 2,
        });
        setTimeout(() => {
          props.history.push('/seasonlist');
        }, 1000);
      } else {
        throw new Error('設問セット紐付けに失敗しました。');
      }
    } catch (error) {
      console.log({ error });
      message.error({
        content: '設問セット紐付け失敗！',
        key: 'makecsv',
        duration: 2,
      });
    }
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '800px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>設問セット割り当て</Title>
        </div>
        <Form onSubmit={onSubmit}>
          <br />
          <Title>❶</Title>
          <Text strong>企業を選択してください。（必須）</Text>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Select
              showSearch
              style={{ marginLeft: 0, marginRight: 10, width: 300 }}
              placeholder="企業選択"
              optionFilterProp="items"
              onChange={onChangeGroup}
              value={selGroupCode}
              filterOption={(input, option) =>
                option.props.items.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
            >
              {groups.map((element) => {
                // console.log({ element });
                return (
                  <Option value={element.group_code} key={element._id}>
                    {element.group_name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <br />
          <div>
            <Title>❷</Title>
            <Text strong>サーベイ・シーズンを選択してください。（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{ marginLeft: 0, marginRight: 5, width: 700 }}
                placeholder="企業選択"
                optionFilterProp="items"
                onChange={onChangeSurveySeason}
                value={selSurveySeasonId}
                disabled={!selGroupCode}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {groupSurveySeasonInfo.map((element) => {
                  // console.log({ element });
                  if (
                    element.survey_code === '20210' ||
                    element.survey_code === '20110' ||
                    element.survey_code === '40010'
                  ) {
                    return (
                      <Option value={element._id} key={element._id}>
                        {`${element.season_name}(${element.season_description})`}
                      </Option>
                    );
                  }
                })}
              </Select>
            </div>
          </div>
          <br />
          <Title>❸</Title>
          <Text strong>設問セットを選択してください。（必須）</Text>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Select
              showSearch
              style={{ marginLeft: 5, marginRight: 5, width: 700 }}
              placeholder="設問セット選択"
              optionFilterProp="items"
              onChange={onChangeQuestionSet}
              value={selQuestionSetId}
              disabled={!selSurveySeasonId}
              filterOption={(input, option) =>
                option.props.items.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
            >
              {questionSet.map((element) => {
                if (!hasQuestionSetId.includes(element._id))
                  return (
                    <Option value={element._id} key={element._id}>
                      {`${element.set_name}(${element.set_description})`}
                    </Option>
                  );
              })}
            </Select>
          </div>
          <br />
          <Title>❹</Title>
          <Button
            type="primary"
            size="large"
            onClick={onSubmit}
            disabled={!(selGroupCode && selSurveySeasonId && selQuestionSetId)}
          >
            割り当て
          </Button>
        </Form>
      </div>
    </Fragment>
  );
};

export default AssignQSetPage;
